footer {
    background-image: url("../../../../public/images/bg_footer_mobile.png");
    padding: 30px 40px 5px 40px;
    font-size: 0.9rem;
    // margin-top: 150px;
    border-top: solid 0.5px rgba(211, 211, 211, 0.3);
    
    @include media('md'){
        background-image: url("../../../../public/images/bg_footer_nav_desktop.png");
    }
}


@media(min-width: 1256px) {
footer {
    margin-top: 100px;
}
}