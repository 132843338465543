/* Acutalites */

.actu_card {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 7px 8px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease-in;

  &:hover {
    transform: translateY(-10px);
  }
}

.actu_show {
  margin-top: 50px;
  padding: 50px;

  .intro {
    font-style: italic;
  }
}

.actu_title {
  margin-left: 10px;

  @include media("md") {
    transform: translate(0);
  }

  transform: translate(-70px, 15px);
}

#actu_back_btn {
  @include media("md") {
    transform: translate(-50px, -35px);
  }
}

.mainImageActu {
  max-width: 750px;
}

.btn-actu {
  border: solid 4px white;
  border-radius: 10px;
  padding: 8px 15px;
  color: black;
  text-decoration: none;
  transition: all 0.5s ease-in;
}

.btn-actu:hover {
  border: solid 4px rgba(0, 113, 185, 0.7);
  border-radius: 10px;
}


/* Histoire */

.frame_histoire {

  @include media('lg') {
    width: 840px;
    height: 500px;
    transform: translate(90px, 40px);
  }
}

.section_histoire {
  margin-bottom: 100px;

  @include media('lg') {
    margin-bottom: 0;
  }

}

.bg_mobile1 {
  height: 900px;

  @include media('lg'){
  height: 70vh;
  }
}

.bg_mobile2 {
  height: 550px;

  @include media('lg'){
  height: 70vh;
  }
}