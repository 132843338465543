.gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 0;
    -webkit-column-gap: 0;
    column-gap: 0;
  }
  
  .gallery_picture {
    margin: 2px;
    width: 99%;
    transition: all 0.5s ease-in;
  }

  figure {
    margin: 2px;
    width: 99%;
	padding: 0;
	background: #fff;
	overflow: hidden;
}

figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.gallery_page {
  background-image: url("../../../../public/images/header_bg.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top;
  background-size: cover;

}

  
  #bg-gallery {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    align-items: center;
    justify-content: center;
  
    &.active {
      display: flex;
    }
  }
  
  .popup-content {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  #popup-img {
    height: auto;
    max-width: 350px;
    max-height : 500px;
  
  @include media('md'){
    max-height: 600px;
    max-width: 900px;
  }
  }
  
  .popup-close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    transition: background-color 0.2s ease;
  
    &:hover {
    background-color: $blue;
    }
  }
  
  @media only screen and (max-width: 1024px) {
  
    .gallery {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
    }
  }
  
  @media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  
    .gallery {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
  
  @media only screen and (max-width: 768px) {
  
    .gallery {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
  
  @media (max-width: 480px) {
    .gallery {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
    }
  
  }

