nav {
  background-image: url("../../../../public/images/bg_footer_nav_desktop.png");
  border-bottom: solid 0.5px rgba(211, 211, 211, 0.3);
}

.navbar-toggler {
    margin-right: 10px;
  }

  .navbar-brand img {
    margin-left: 20px;
  }

.navbar-nav {
    display: flex;
    justify-content: center;
    text-align: center;

    a {
        margin-bottom: 10px;

        @include media ('xl') {
          margin-right: 200px;
        }
    }

    .dropdown-menu {
      margin: 0 10px;
      text-align: center;
      width: 80%;
    }
}

#navbarNavAltMarkup {
  @include media('xl'){
    justify-content: end;
  }
}


	@media(min-width: 1256px) {
	.navbar-nav a {
				margin-right: 10px;
			}

			.navbar-nav {
				justify-content: space-evenly;
				width: 100%;
			}

			.navbar-nav .dropdown-menu {
				width: 250px;
			}
	}
