.margin_frame {
    margin: 130px auto;
}

.flash {
    text-align: center;
    font-size: 1.3rem;
    padding: 20px 0;
    margin: 0 auto;
    border-radius: 10px;
    width: 50%;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  
    &-success{
      background-color: rgba(0, 113, 185, 0.5);
    }
    &-error{
      background-color: rgba(176,65,62,0.6);
    }
  
    @include media('md'){
      width: 90%;
    }
  }

  
