@include media('xl') {
	.illu_form {
		background: linear-gradient(
		to right, 
		#ffffff 0%, 
		#ffffff 52%, 
		#000000 52%, 
		#000000 100%
	  );
	}
}

.anim_txt{
	position: absolute;
	top: 40%;
}

.mobile_off{
  display: none;
}

.mobile_off h3 {
  font-size: 6rem;
  color: #fff;
	position: absolute;
	font-family: 'Century Gothic';

}

.mobile_off h3:nth-child(1){
  color: transparent;
-webkit-text-stroke: 2px #0071b9;
font-family: 'Century Gothic';
}

.mobile_off h3:nth-child(2){
  color: #0071b9;
  animation: animate 4s ease-in-out infinite;
  font-family: 'Century Gothic';
}

.datepicker-modal {
  height: fit-content;
}

.shadowBtn{
	width: 300px;
	height: 120px;
	border: 2px solid $blue;
	background-color: transparent;
	border-radius: 10px;
	color: $blue;
	font-size: 1.5rem;
	cursor: pointer;
	position: relative;
}

.shadowBtn:hover{
	background-color: $blue;
	color: white;
	transition: 1s ease;
}

.shadowBtn::before{
	content: '';
	position: absolute;
	left: 0;
	top: 50px;
	background: transparent;
	height: 30%;
	width: 100%;
	transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
	filter: blur(8px);
	transition: 1s ease;
}

.shadowBtn:hover::before{
	background-color: $blue;
	top: 120px;
}

.formulaire{
	margin-top: 50px;

	@include media('lg'){
	margin-top: 120px;
	}

	h3 {
		color: #0071b9;
		border : solid 1px #0071b9;
		border-radius: 10px;
		box-shadow: 5px 5px 8px rgba(0,0,0,0.2);
		padding: 20px;
		font-size: 2.3rem;
		margin-top: 50px;
		margin-bottom: 150px;
	}

}


@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}