@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Importing variables and components
@import "include-scss/utils/variables";
@import "include-scss/utils/mixin";
@import "include-scss/utils/components";
@import "include-scss/layout/navbar";
@import "include-scss/layout/footer";

// Importing pages
@import "include-scss/pages/home";
@import "include-scss/pages/about";
@import "include-scss/pages/products";
@import "include-scss/pages/contact";
@import "include-scss/pages/galerie";

// Base css
*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
}

body {
  padding-top: 67px;
  letter-spacing: 0.5px;
  overflow-x: hidden;
  font-family: 'Century Gothic';
  color: var(--bs-body-color);
}

.container {
  margin-top: 100px;
}

.container-fluid {
  padding: 0;
}
