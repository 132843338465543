header {
    height: 90vh;
    background-image: url("../../../../public/images/header_bg.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top;
    background-size: cover;
    padding: 0 10px;
    border-bottom: solid 0.5px rgba(211, 211, 211, 0.3);

    @include media('md') {
        padding: 0 100px;
    }

    h2 {
        margin-top: 50px;
        color: #fff;
    }
}

.section_home {
    margin-bottom: 50px;

    @include media('lg'){
        height: 70vh;
        margin-bottom: 0;
    }
}

header,
.section_home {
    display: flex;
    align-items: center;
}

.frame {
    border: solid 4px #F1F8FC;
    width: 290px;
    height: 350px;
    position: absolute;
    transform: translate(30px, 45px);
    z-index: -1;

    @include media('md') {
        width: 750px;
        height: 200px;
        transform: translate(80px, 20px);
    }
}

.blue_bg {
    background-color: $light_blue;
    position: absolute;
    z-index: -1;
    height: 70vh;

    @include media('lg') {
        width: 90vw;
    }
}

.left {
    left: 0;

    padding: 30px;
    margin-bottom: 50px;

    @include media('lg'){
        margin-bottom: 0;
        padding: 0;
    }

}

.right {
    right: 0;
}

.newbac {
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: 1.2rem;
    color: #53565a;
}

.main-header-title {
    @include media('lg') {
        width: 90vw;
    }
}

.frame_2_mobile {

width: 335px;
height: 950px;
transform: translateY(30px);

    @include media('lg')
    {
        height: 370px;
        width: 750px;
        transform: translate(110px, 40px);
    }
}