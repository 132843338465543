.card{
    
    border: none;
    
    

h3 {
    color: white;
    font-weight: 500;
    font-size: 1.5rem;

    @include media('lg'){
        font-size: 2.8rem;
    }
}

.card-body{
    border-radius: 10px;
    z-index: 99;
    position: absolute;
    transition: all 0.5s ease-in-out;
    height: 200px;
    width: 200px;
    
    @include media('lg'){
        height: 350px;
        width: 350px;
    }
}

.card_se {
    background-image: url('../../../../public/images/card_stopevap.png');
    background-size: contain;
}

.card_boulbac {
    background-image: url('../../../../public/images/image_produit_ocapt_175.png');
    background-size: contain;
}

.hover-card {
    background-color: $light_blue;
    border-radius: 10px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
    height: 200px;
    width: 200px;
    padding: 10px;

    @include media('lg'){
        padding: 20px;
        height: 350px;
        width: 350px;
    }
}
}

.card:hover .hover-card{
    transform: translate(2.2rem, 2.2rem);

    @include media('lg'){
        transform: translate(3.5rem, 3.5rem);
    }
}

.card:hover .card-body {
    transform: translate(-2.2rem, -2.2rem);
    
    @include media('lg'){
        transform: translate(-3.5rem, -3.5rem);
    }
}

.image_produit{
    box-shadow: 8px 10px 15px rgba(0,0,0,0.4);
}

.stop_title {
    color: $blue;
    font-size: 3.5rem;

    @include media('lg'){
        font-size: 5rem;
    }
}

.stop_image {
    box-shadow: 10px 10px 12px rgba(0,0,0,0.4);
    margin-bottom: 50px;

    @include media('lg'){
        margin-bottom: 0;
    }
}

.stop_blue {
    color: $blue;
    font-weight: bold;
}

#caracteristiques_stop, #condi_stop {
   display: none;
}

.condi_btn, .carac_btn, .dimension_btn {
    cursor: pointer;
}

.desktop-only {
    display: none;

    @include media ('lg'){
        display: block;
    }
}

.mobile-only {
    display: block;

    @include media ('lg'){
        display: none;
    }
}

